document.addEventListener("DOMContentLoaded", function () {
  // Seleccionar el fondo del modal y obtener la imagen de fondo
  var modalFondo = document.querySelector(".modal-fondo");
  if (modalFondo) {
    var bgImage = modalFondo.getAttribute("data-bg");

    if (bgImage) {
      if (!bgImage.startsWith("url(")) {
        bgImage = `url(${bgImage})`;
      }
      modalFondo.style.backgroundImage = bgImage;
    }
  }

  // Desactivar el desplazamiento del cuerpo cuando el modal esté abierto
  document.body.classList.add("no-scroll");

  // Seleccionar el modal
  var modal = document.querySelector(".modal");

  // Mostrar el modal con animaciones después de un retardo
  if (modal) {
    setTimeout(function () {
      modal.classList.add("show_modal");
      modal.classList.add("fadeIn");
    }, 500);
  }

  // Manejar el cierre del modal al hacer clic en los botones
  document.querySelectorAll(".btn_modal").forEach(function (button) {
    button.addEventListener("click", function () {
      setTimeout(function () {
        if (modal) {
          modal.classList.add("animationless");
          modal.classList.remove("fadeIn", "show_modal");
        }
        document.body.classList.remove("no-scroll");
      }, 300);
    });
  });
});
